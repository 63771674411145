/* -The main header area with logo and navigation
-----------------------------------------------------------------------------*/
.site-header {
  padding: 30px 40px;
  display: flex;
  width: 100%;

  @include when-page-margin-small {
    padding: ($page-margin-small - rem(5px)) $page-margin-small;
  }
}
.site-header-logo {
  flex-shrink: 0;
  padding-right: 30px;
}

// Nav menu
.site-header-nav {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  font-family: $font-stack-main;
  font-weight: $font-weight-bold;

  ul {
    text-align: right;
    display: flex;
    align-items: center;
  }
  li {
    margin-left: 25px;
    line-height: 1;

    &:first-child {
      margin-left: 0;
    }

    &.active,
    &:hover,
    &:focus {
      a {
        .text {
          border-bottom: 2px solid;
        }
      }
    }
  }
  a {
    .text {
      padding-bottom: 1px;
      border-bottom: 2px solid transparent;
    }
    color: $color-blue;
  }
}

@include media-min($breakpoint-menu-toggle) {
  .menu-item--callout {
    a {
      @include button-primary;

      .has-intro & {
        @include button-secondary;
      }

      .text {
        padding-bottom: 0 !important;
        border-bottom: 0 !important;
      }
    }
  }
}

// Toggle button menu

$menu-toggle-height: 40px;
.menu-toggle {
  display: none;
  position: relative;
  padding-right: 35px;
  padding-left: 15px;
  height: rem($menu-toggle-height);
  line-height: rem($menu-toggle-height - 2px);
  color: inherit;
  border: 1px solid;
  box-shadow: none;
  background: transparent !important;
  font-weight: 400;
  font-size: rem(16px);
  text-transform: uppercase;
  transition: color 0.15s ease;

  .has-intro & {
    color: #fff;
  }

  .nav-open & {
    @include theme-foreground;
  }
}

// Toggled menu
@include media-max($breakpoint-menu-toggle) {
  $header-small-height: 70px;

  .site-header {
    position: relative;
    height: $header-small-height;
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .menu-toggle {
    @include theme-foreground;

    display: block;

    .nav-open & {
      border: 1px solid;
      z-index: $z-header + 10;
      transition: color 0.15s ease; // Button text fades in
    }
  }

  .menu-toggle-icon {
    top: 50%;
    right: 15px;
    margin-top: -1px;

    &,
    &::before,
    &::after {
      position: absolute;
      width: 13px;
      height: 2px;
      background-color: currentColor;
      transition: background-color 0.15s ease;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      left: 0;
      transition: transform 0.15s ease;
    }
    &::before {
      top: 0;
      transform: translateY(-5px);
    }
    &::after {
      bottom: 0;
      transform: translateY(5px);
    }
    .nav-open & {
      background-color: transparent;

      &::before {
        transform: translateY(0) rotate(-225deg);
      }
      &::after {
        transform: translateY(0) rotate(225deg);
      }
    }
  }

  $toggled-menu-transition: 0.2s;
  // The pseudo elements are the black transparent and white opaque backgrounds
  .site-header-nav::before,
  .site-header-nav::after {
    content: '';
    display: block;
    position: absolute;
    height: $header-small-height;
    top: 0;
    right: 0;
  }

  .main-nav,
  .site-header-nav::before {
    visibility: hidden;
    width: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color $toggled-menu-transition ease,
      visibility 0s ease $toggled-menu-transition,
      width 0s ease $toggled-menu-transition;

    .nav-open & {
      visibility: visible;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition-delay: 0s;
    }
  }

  .main-nav > ul,
  .site-header-nav::after {
    @include theme-background;
    width: 0;
    max-width: 350px;
    transform: translateX(100%);
    transition: transform $toggled-menu-transition ease;

    .nav-open & {
      transform: translateX(0);
      width: 100%;
    }

    @include media-max(rem(450px)) {
      max-width: none;
    }
  }

  .main-nav {
    position: absolute;
    top: 100%;
    left: 0;
    height: calc(100vh - #{$header-small-height});
    overflow-x: hidden;
    overflow-y: auto;
    text-align: right;
    z-index: $z-index-7;

    ul {
      @include theme-rule(
        $rule: border-top,
        $color-name: 'separator',
        $value-template: '1px solid {{color}}'
      );

      display: inline-block;
      min-height: 100%;
    }
    li {
      @include theme-rule(
        $rule: border-bottom,
        $color-name: 'separator',
        $value-template: '1px solid {{color}}'
      );

      display: block;
      text-align: left;
      text-transform: none;
      margin: 0;
      padding: 0;

      &.active,
      &:hover,
      &:focus {
        a {
          background-color: rgba(0, 0, 0, 0.1);

          .text {
            padding-bottom: 0;
            border-bottom: 0;
          }
        }
      }
    }

    a {
      @include theme-foreground;
      padding: 10px 25px;
      border-radius: 0;
    }
  }

  .nav-open,
  .nav-open body {
    overflow: hidden;
  }
  .nav-open {
    .intro-content {
      z-index: 1;
    }
  }
} // end MQ media-max($breakpoint-menu-toggle)

/* ------------------- With intro ------------------- */
.has-intro {
  .site-header {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    z-index: $z-header;
  }

  @include media-min($breakpoint-menu-toggle) {
    .site-header-nav {
      a {
        color: #fff;
      }
    }
  }
}
