/* -'Image and content' block skewed
-----------------------------------------------------------------------------*/
// Match breakpoint with picture element sources
$skewed-image-content-breakpoint-two-columns: rem(800px);

.image-content-block-skewed {
  @include section-margin;
}

.image-content-block-skewed-inner {
  @include theme-rule(
    $rule: background-color,
    $color-name: 'background-secondary'
  );

  clip-path: polygon(0 0, 100% 2%, 100% 98%, 0% 100%);
  min-height: 330px;
  font-size: rem(16px);

  @include media-min(rem(600px)) {
    clip-path: polygon(0 0, 100% 4%, 100% 96%, 0% 100%);

    .image-content-block-skewed-content {
      padding: 50px;
    }
  }

  @include media-min($skewed-image-content-breakpoint-two-columns) {
    clip-path: polygon(0 0, 100% 8%, 96% 96%, 2% 100%);
    display: flex;
    font-size: rem(18px);
  }
}

.image-content-block-skewed-content {
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  > *:first-child {
    margin-top: 0 !important;
  }

  a {
    color: inherit;
  }

  @include media-min($skewed-image-content-breakpoint-two-columns) {
    padding: 0 !important;
    width: 53%;
    margin: 80px 6% 50px 5%;
  }
}

.image-content-block-skewed-image {
  height: 300px;
  position: relative;

  @include media-min(rem(600px)) {
    height: 400px;
  }

  @include media-min($skewed-image-content-breakpoint-two-columns) {
    width: 35%;
    height: auto;
  }
  div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

// Reversed
.image-content-block-skewed:nth-child(2n) {
  .image-content-block-skewed-inner {
    flex-direction: row-reverse;
    clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 98%);

    @include media-min(rem(600px)) {
      clip-path: polygon(0 4%, 100% 0, 100% 100%, 0 96%);
    }

    @include media-min($skewed-image-content-breakpoint-two-columns) {
      clip-path: polygon(0 8%, 100% 0, 98% 100%, 4% 96%);
      display: flex;
      font-size: rem(18px);
    }
  }

  .image-content-block-skewed-content {
    @include media-min($skewed-image-content-breakpoint-two-columns) {
      margin: 80px 5% 50px 6%;
    }
  }
}
