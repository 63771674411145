/* -The global footer area
-----------------------------------------------------------------------------*/
$site-footer-spacing: 40px;
$site-footer-padding: 40px;

.site-footer {
  @include theme-foreground;
  @include theme-background;

  @include theme-rule(background-color, 'background-footer');
  @include theme-rule(color, 'foreground-footer');

  padding-top: $site-footer-padding;
  padding-bottom: $site-footer-padding;

  ul {
    margin: 0;
    list-style: none;
  }
}

.footer-content-col {
  margin-bottom: $site-footer-spacing;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }
}

.footer-content-phone {
  a {
    text-decoration: none;
    color: inherit;
  }
}

.footer-icon {
  flex-shrink: 0;
  margin-right: 30px;

  img {
    width: 60px;
    height: 60px;
  }
}

.footer-col-title {
  font-weight: $font-weight-bold;
}

@include media-min(rem(750px)) {
  .site-footer {
    display: flex;
    align-items: center;
  }
  .footer-content {
    display: flex;
  }
  .footer-content-col {
    margin-bottom: 0;
    margin-left: 30px;

    &:first-child {
      margin-left: 0;
    }
  }
  .footer-icon {
    margin-right: 20px;
  }
}

@include media-min(rem(1000px)) {
  .footer-content-col {
    margin-left: 80px;
  }
  .footer-icon {
    margin-right: 30px;

    img {
      width: 80px;
      height: 80px;
    }
  }
}
