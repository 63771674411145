/* -Link with an arrow icon
---------------------------------------------------------------------------- */
a.arrow-link {
  display: inline-block;
  padding: $arrow-link-padding 0;
  text-decoration: none;
  font-weight: $font-weight-medium;

  &:hover,
  &:focus {
    .text {
      text-decoration: underline;
    }
  }
  .icon--arrow {
    height: 15px;
    width: 15px;

    @include media-min($breakpoint-type-medium) {
      height: 22px;
      width: 22px;
    }
  }
  .icon--arrow:first-child {
    margin-right: 1em;
    transform: rotate(180deg);
  }
  .icon--arrow:last-child {
    margin-left: 1em;
  }
}
