/* -Base
-----------------------------------------------------------------------------*/
html {
  font-size: $base-font-size;
}
body {
  @include theme-background;
  @include theme-foreground;

  font-size: 1em;
  line-height: $base-line-height;
  tab-size: 4;
}
body,
button,
input,
optgroup,
select,
textarea {
  font-family: $font-stack-main;
  @include theme-foreground;
}

::selection {
  @include theme-rule(
    $rule: background-color,
    $color-name: 'selection-background-color'
  );
  @include theme-light-dark($rule: color, $reverse: true);

  text-shadow: none;
}

/* -Text element spacing
-----------------------------------------------------------------------------*/
p,
blockquote,
dl,
ol,
ul,
pre,
table {
  margin-bottom: $text-spacing;
}
dl dd,
dl dl {
  margin-left: $text-spacing;
}

ol,
ul {
  li & {
    margin-bottom: 0;
  }
  nav & {
    margin: 0;
    list-style: none;
    list-style-image: none;
  }
  ol,
  ul {
    margin-bottom: 0;
  }
}
ul {
  list-style: disc outside;
  margin-left: 1em;

  ul {
    list-style-type: square;
    margin-left: $text-spacing;

    ul {
      list-style-type: circle;
    }
  }
}
ol {
  list-style: decimal outside;
  margin-left: 1.5em;

  ol {
    list-style-type: upper-roman;
    margin-left: $text-spacing;

    ol {
      list-style-type: upper-alpha;
    }
  }
}

/* -Text element styles
-----------------------------------------------------------------------------*/
blockquote,
q {
  quotes: none;
}
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}
blockquote {
  position: relative;
  margin-top: $text-spacing;
  margin-bottom: $text-spacing;
  font-weight: $font-weight-bold;
  font-size: rem(18px);

  @include media-min($breakpoint-type-small) {
    font-size: rem(26px);
  }
  @include media-min($breakpoint-type-medium) {
    font-size: rem(30px);
  }

  p,
  ol,
  ul {
    margin-top: 0.75em;
    margin-bottom: 0;
    line-height: 1.35;

    &:first-child {
      margin-top: 0;
    }
  }
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
  cursor: help;
}
cite,
dfn,
q {
  font-style: italic;
}
b,
strong,
dt,
mark,
th {
  font-weight: $font-weight-semibold;
}
del {
  @include theme-rule(
    $rule: color,
    $color-name: 'foreground',
    $tint-shade-amount: 20%,
    $tint-shade-reverse: true
  );

  text-decoration: line-through;
}
ins,
mark {
  @include theme-foreground;

  background: #ffa;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: $font-stack-mono;
  font-size: em(14px);
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}
small {
  font-size: em(12px);
}
sub,
sup {
  font-size: em(12px);
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
