/* -Callout block
---------------------------------------------------------------------------- */
.callout-block-wrap {
  @include section-margin;
  @include large-content-text;
}

.callout-block-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;

  img {
    max-height: 250px;
    max-width: 300px;

    @include media-min(rem(500px)) {
      max-height: 300px;
      max-width: 400px;
    }
  }
}

.callout-block-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  a {
    color: inherit;
  }
}

// Larger screens only
@include media-min(rem(700px)) {
  .callout-block {
    display: flex;
  }

  .callout-block-content {
    width: 60%;
  }
  .callout-block-image {
    margin-bottom: 0;
    margin-right: 60px;

    img {
      height: auto;
      max-height: 320px;
      max-width: 200px;
    }
  }
}

@include media-min(rem(900px)) {
  .callout-block-image {
    margin-right: 80px;

    img {
      height: auto;
      max-height: 420px;
      max-width: 300px;
    }
  }
}
