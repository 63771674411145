/* -Blurb component - block of image, heading, short text and a link
-----------------------------------------------------------------------------*/
.blurb {
  max-width: 800px;
  text-align: left;
}
.blurb-inner {
  display: flex;
}
.blurb-image {
  flex-shrink: 0;
  margin-right: 15px;
  @include media-min(rem(400px)) {
    margin-right: 30px;
  }
}

.blurb--img {
  .blurb-image {
    flex-basis: 20%;
  }
}

.blurb-content {
  p {
    margin: 0;
  }
  @include media-min($breakpoint-type-small) {
    font-size: rem(18px);
  }
}

.blurb-title {
  margin-bottom: 5px;
}

.blurb-text {
  margin-bottom: 5px;
}

/*--------------- List of blurbs (blurbs block, news...) ---------------*/

$grid-gutter-blurb: 40px;
$grid-gutter-blurb-tight: $grid-gutter-blurb / 2;

.blurb-list-wrap {
  @include section-margin;
}
.blurb-list-wrap--cols-3 {
  @include wrap-width-wide;
}
.blurb-list-wrap--cols-4 {
  @include wrap-width-wide;
}

.blurb-list {
  @include grid-container($grid-gutter-blurb, $grid-gutter-blurb-tight);
  @include grid-pull-bottom($grid-gutter-blurb, $grid-gutter-blurb-tight);
  width: 100%;

  .blurb {
    @include grid-item(1, $grid-gutter-blurb, $grid-gutter-blurb-tight);
    @include grid-margin-bottom($grid-gutter-blurb, $grid-gutter-blurb-tight);
  }
}

@include media-min(rem(800px)) {
  .blurb-list--cols-2,
  .blurb-list--cols-3,
  .blurb-list--cols-4 {
    text-align: left;

    .blurb {
      @include grid-item(1 / 2, $grid-gutter-blurb, $grid-gutter-blurb-tight);
    }
  }

  .blurb-list-wrap--cols-4 {
    @include wrap-width-thin;
  }

  .blurb-list--count-4 {
    .blurb {
      @include grid-item(1 / 2, $grid-gutter-blurb, $grid-gutter-blurb-tight);
    }
    text-align: left;
  }
}

@include media-min(rem(1250px)) {
  .blurb-list--cols-3 .blurb,
  .blurb-list--cols-4 .blurb {
    @include grid-item(1 / 3, $grid-gutter-blurb, $grid-gutter-blurb-tight);
  }

  .blurb-list-wrap--cols-4 {
    @include wrap-width-wide;
  }

  .blurb-list--count-4 .blurb {
    @include grid-item(1 / 4, $grid-gutter-blurb, $grid-gutter-blurb-tight);
  }
}
