/* -Card used for news
---------------------------------------------------------------------------- */
.news-card {
  position: relative;
  max-width: 500px;
}

.news-card-inner {
  position: relative;
  width: 100%;

  .overlay-link:hover ~ &,
  .overlay-link:focus ~ & {
    @include theme-rule(
      $rule: box-shadow,
      $color-name: 'foreground',
      $value-template: 'inset 0 0 0 2px {{color}}'
    );
  }
}

.news-card-image {
  margin-top: auto;
  position: relative;
  // https://github.com/philipwalton/flexbugs#flexbug-5
  // https://github.com/philipwalton/flexbugs/issues/75#issuecomment-161800607
  min-height: 1px;

  img {
    width: 100%;
  }
}
.news-card-date {
  font-weight: $font-weight-light;
  margin-bottom: 10px;
}

.news-card-title {
  margin-bottom: 10px;
}
.news-card-title--small {
  font-size: rem(20px) !important;
}

.news-card-excerpt {
  margin-bottom: 1em;
  font-size: rem(17px);
}

.news-card-inner {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #fff;
  @include theme-rule(
    $rule: background-color,
    $color-name: 'background-secondary'
  );
}

.news-card--no-image {
  .news-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .news-card-date {
    margin-bottom: 10px;
  }
}

@include media-max(rem(450px)) {
  .news-card-inner {
    padding: 15px;
  }
}
