/* -'Image and content' block
-----------------------------------------------------------------------------*/
$image-content-columns-breakpoint: 750px;
$image-content-columns-gutter: 40px;

.image-content-block {
  @include section-margin;
  @include large-content-text;
}

.image-content-block-image {
  margin-top: 1em;
  display: flex;
  justify-content: center;

  img {
    max-height: 200px;
  }
}

// Go to two columns
@include media-min(rem($image-content-columns-breakpoint)) {
  .image-content-block-inner {
    display: flex;
  }
  .image-content-block:nth-child(2n + 1) {
    .image-content-block-image {
      margin-left: $image-content-columns-gutter;
    }
  }
  .image-content-block:nth-child(2n) {
    .image-content-block-inner {
      flex-direction: row-reverse;
    }
    .image-content-block-image {
      margin-right: $image-content-columns-gutter;
    }
  }
  .image-content-block-image {
    display: block;
    width: 40%;
    margin-top: 0;
    img {
      max-height: none;
    }
  }
  .image-content-block-content {
    width: 60%;
  }
}
