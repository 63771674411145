/* -Logotype list block with logos
---------------------------------------------------------------------------- */
$logo-cols-gutter: 100px;
$logo-cols-gutter-small: 30px;

.logotype-list-block {
  @include section-margin;
  @include wrap-thin;
}

.logotype-list-block-items {
  @include grid-container($logo-cols-gutter, $logo-cols-gutter-small);
  @include grid-pull-bottom($logo-cols-gutter-small, $logo-cols-gutter-small);

  justify-content: center;
  align-items: center;
}

.logotype-list-block-item {
  @include grid-item(1, $logo-cols-gutter, $logo-cols-gutter-small);
  @include grid-margin-bottom($logo-cols-gutter-small, $logo-cols-gutter-small);

  text-align: center;

  img {
    max-width: 80px;
    max-height: 50px;

    @include media-min(rem(600px)) {
      max-width: 130px;
      max-height: 80px;
    }
  }
}

.logotype-list-block--cols-2 {
  @include wrap-custom(rem(350px));

  .logotype-list-block-item {
    @include grid-item-size(1/2);
  }
}

.logotype-list-block--cols-3 {
  @include wrap-extra-thin;

  .logotype-list-block-item {
    @include grid-item-size(1/3);
  }
}

.logotype-list-block--cols-4 {
  .logotype-list-block-item {
    @include media-min(rem(400px)) {
      @include grid-item-size(1/4);
    }
  }
}

.logotype-list-block--cols-5 {
  .logotype-list-block-item {
    @include media-min(rem(400px)) {
      @include grid-item-size(1/4);
    }
    @include media-min(rem(500px)) {
      @include grid-item-size(1/5);
    }
  }
}
