/* -Buttons
-----------------------------------------------------------------------------*/

/*---------- Mixins ----------*/
@mixin button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $button-height;
  padding: 0 20px;
  border: 0;
  border-radius: $form-control-radius;
  font-weight: $font-weight-bold;
  font-size: rem($button-font-size-small);
  line-height: $button-height - em(2px, $button-font-size-small);
  text-decoration: none;
  text-align: center;
  text-shadow: none;
  vertical-align: middle;
  box-shadow: none;
  transition: background-color 0.15s ease-out;

  @include media-min($breakpoint-type-small) {
    font-size: rem($button-font-size-large);
    line-height: $button-height - em(2px, $button-font-size-large);
  }
}

@mixin button-primary-base-colors {
  @include theme-rule(
    $rule: background-color,
    $color-name: 'primary-button-background'
  );

  color: #fff;
}
@mixin button-primary-colors {
  @include button-primary-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      @include theme-rule(
        $rule: background-color,
        $color-name: 'primary-button-background',
        $tint-shade-amount: 15%
      );
    }
  }
}
@mixin button-primary {
  @include button;
  @include button-primary-colors;

  &[disabled] {
    opacity: 0.5;
  }

  &:focus {
    outline: 3px dotted $color-blue !important;
  }
}

@mixin button-secondary-base-colors {
  @include theme-rule(
    $rule: background-color,
    $color-name: 'secondary-button-background'
  );
  @include theme-rule(
    $rule: color,
    $color-name: 'secondary-button-foreground',
    $important: true
  );
}
@mixin button-secondary-colors {
  @include button-secondary-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      @include theme-rule(
        $rule: background-color,
        $color-name: 'secondary-button-background',
        $tint-shade-amount: 15%
      );
    }
  }
}
@mixin button-secondary {
  @include button;
  @include button-secondary-colors;

  &:focus {
    outline: 3px dotted #fff !important;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

/*---------- Standard button classes ----------*/
.btn,
a.btn,
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  @include button-primary;

  &[disabled] {
    cursor: default;
  }
  > span {
    vertical-align: middle;
  }
  .icon,
  .spinner {
    &:first-child {
      margin-right: 0.5em;
    }
    &:last-child {
      margin-left: 0.5em;
    }
    &:only-child {
      margin-left: 0;
      margin-right: 0;
      vertical-align: top;
    }
  }
}

a.btn-secondary,
.btn-secondary {
  @include button-secondary;
}

// ----- Special use case buttons -----

// Semantically a button but doesn't have any styles to indicate it
@mixin btn-neutral {
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background-color: transparent !important;
  color: inherit !important;
}
.btn-neutral {
  @include btn-neutral;

  .no-js & {
    cursor: default;
  }
}
