/* -Section block
---------------------------------------------------------------------------- */
.section-block {
  @include section-padding;

  > div:first-child {
    margin-top: 0 !important;
  }
  > div:last-child {
    margin-bottom: 0 !important;
  }
}

.section-block--center {
  .block-content {
    text-align: center;
  }
}

/** Section with no theme */
.section-block--no-theme {
  @include section-margin;

  padding-top: 0;
  padding-bottom: 0;
}

/** Section white */
.section-block--white {
  @include theme-background-secondary;
}

/** Section block point down*/
.section-block--white-point-down {
  @include section-padding-bottom(2);
  @include theme-rule(
    $rule: background-color,
    $color-name: 'background-secondary'
  );
  clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);

  @include media-min(rem(600px)) {
    clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
  }

  @include media-min(rem(1000px)) {
    clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
  }
}

/** Section block with selected background image */
.section-block--background-image {
  @include section-padding(0);
  display: flex;
  flex-direction: column;

  &.section-block--light-text {
    color: #fff !important;
  }
  .section-block--dark-text {
    color: #333;
  }
  .btn {
    background: transparent !important;
    color: #fff;
    border: 2px solid #fff;

    &:focus,
    &:hover {
      border-color: #adadad;
    }
  }

  .section-block-background-item {
    @include section-padding;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .bg-image {
      &,
      picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .section-content {
    position: relative;
    z-index: 2;

    > div:first-child {
      margin-top: 0 !important;
    }
    > div:last-child {
      margin-bottom: 0 !important;
    }
  }
}

/** Section block theme Sajkla */
.section-block--sajkla {
  @include section-padding(0);

  position: relative;
  overflow: hidden;
}

/** Skewed section block with background color */
.section-block--color-background-skew {
  color: #333;
  position: relative;
  overflow: hidden;
  background: #fff;
  clip-path: polygon(0 0, 100% 40px, 100% 100%, 0% 100%);

  @include media-min(rem(700px)) {
    clip-path: polygon(0 0, 100% 80px, 100% 100%, 0% 100%);
  }
}

/* -'Green and white' section JAK
-----------------------------------------------------------------------------*/
$color-gray-jak: #f4f4f4;
$color-green-jak: #0e6946;
.section-block--green-and-white {
  @include theme-background-secondary;

  position: relative;
  z-index: 1;
  color: #333;

  // Small screens
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  &::before {
    height: 50%;
    top: 50%;
    transform: translateY(-50%) skewY(12deg);
    background-color: $color-green-jak;
    z-index: -1;
  }

  &::after {
    height: 85%;
    top: 15%;
    background-color: $color-gray-jak;
    z-index: -1;
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }

  // Medium screens
  @include media-between(rem(650px), rem(1099px)) {
    &::before {
      height: 50%;
      top: 50%;
      transform: translateY(-50%) skewY(12deg);
    }

    &::after {
      height: 70%;
      top: 50%;
      transform: translateY(-50%) skewY(-7deg);
      clip-path: none;
    }
  }

  // Large screens
  @include media-min(rem(1100px)) {
    &::before {
      height: 40%;
      top: 50%;
      transform: translateY(-50%) skewY(6deg);
      background-color: $color-green-jak;
    }

    &::after {
      height: 60%;
      top: 50%;
      transform: translateY(-50%) skewY(-6deg);
      background-color: $color-gray-jak;
      clip-path: none;
    }
  }
}

/* -'White background and image to right' section
-----------------------------------------------------------------------------*/
.section-block--white-image-to-right {
  @include theme-background-secondary;

  overflow: hidden;
  position: relative;
}

/* -'Dark grey' section
-----------------------------------------------------------------------------*/
.section-block--dark-grey {
  position: relative;
  overflow: hidden;
  background-color: #444;
  color: #fff;

  &::before {
    @include theme-background-secondary;

    content: '';
    display: block;
    position: absolute;
    height: 90px;
    width: 100%;
    transform: skewY(3deg);
    top: -90px;
    transform-origin: top left;
    z-index: 3;
  }

  @include media-min(rem(800px)) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 160px;
      width: 100%;
      transform: skewY(2deg);
      top: -100px;
      transform-origin: top left;
      z-index: 3;
      background-color: #fff;
    }
  }
}

/* -'Light grey' section
-----------------------------------------------------------------------------*/
.section-block--light-grey {
  position: relative;
  overflow: hidden;
  background-color: #f4f4f4;
  color: #333;
}

/* -'white with image to left' section
-----------------------------------------------------------------------------*/
.section-block--white-image-to-left {
  @include theme-background-secondary;
}

/* -'Red' section
-----------------------------------------------------------------------------*/
.section-block--red {
  position: relative;
  overflow: hidden;
  background-color: #c6493c;
  color: #fff;
  z-index: 1;

  &::before {
    @include theme-background-secondary;

    content: '';
    display: block;
    position: absolute;
    height: 250px;
    width: 100%;
    transform: skewY(-6deg);
    top: -150px;
    transform-origin: top right;
    z-index: -1;
  }

  @include media-min(rem(1100px)) {
    &::before {
      height: 100px;
      top: -100px;
      transform: skewY(-2deg);
    }
  }
}
