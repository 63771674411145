/* -Latest news block
-----------------------------------------------------------------------------*/
.latest-news-block-wrap {
  @include section-margin;
  @include large-content-text;

  text-align: center;

  @include media-max($breakpoint-type-small) {
    font-size: rem(14px);
  }
}

.latest-news-block-title {
  margin-bottom: 20px;
}

.latest-news-block-list {
  padding-top: 20px;
  margin: 0;
  list-style: none;
  text-align: left;
}

.latest-news-block-item {
  display: flex;
  border-top: 1px solid;

  &,
  &:last-child {
    border-bottom: 1px solid;

    @include theme-rule(
      $rule: border-color,
      $color-name: 'separator',
      $tint-shade-amount: 70%,
      $tint-shade-reverse: true
    );
  }

  .publish-date {
    flex-shrink: 0;
    width: 85px;
    padding-top: 10px;
    margin: 0;
    font-weight: $font-weight-light;

    @include media-min($breakpoint-type-small) {
      width: 120px;
      margin-right: 10px;
      font-size: rem(18px);
    }
    @include media-min(rem(700px)) {
      margin-right: 30px;
      padding-top: 20px;
    }
  }

  a {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    color: inherit;

    @include media-min(rem(700px)) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .icon--arrow {
    flex-shrink: 0;
    margin-left: 10px;
    width: 15px;
    height: 15px;
    @include media-min(rem(700px)) {
      width: 22px;
      height: 22px;
    }
  }
}
.latest-news-article-title {
  max-width: 700px;
  font-weight: $font-weight-medium;
}

.latest-news-block-link {
  margin-top: 15px;
}
