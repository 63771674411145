// -------------------- General color definitions -------------------- //

$color-blue: #1a4dcd;
// Forms
$color-form-field-border: #ccc;

// Status
$color-error: #ed404b;
$color-error-foreground: #d71421;
$color-error-background: #fae8e6;
$color-success: #49ad5a;
$color-success-foreground: #337a3b;
$color-success-background: #e7fae6;
$color-info-foreground: #06608c;
$color-info-background: #d5e9f9;
$color-warning-foreground: #715100;
$color-warning-background: #fffbd5;

// -------------------- Private color definitions -------------------- //

// Main body colors
$__color-theme-light-foreground: #333;
$__color-theme-light-background: #fff;
$__color-theme-light-secondary-background: #f4f4f4;
$__color-theme-light-brand: #1a4dcd;

$__color-theme-dark-foreground: #fff;
$__color-theme-dark-background: #252525;
$__color-theme-dark-secondary-background: #181818;

/* -Color themes
-----------------------------------------------------------------------------*/
// Theme color definitions
$default-theme: 'light';
$themes: (
  light: (
    type: 'light',
    background: $__color-theme-light-background,
    foreground: $__color-theme-light-foreground,
    background-footer: #113898,
    foreground-footer: #fff,
    background-secondary: $__color-theme-light-secondary-background,
    background-gradient:
      linear-gradient(
        90deg,
        rgba(245, 129, 129, 0) 10%,
        rgba(255, 255, 255, 0.7) 50%,
        transparent 90%
      ),
    background-lines-news:
      svg-url(
        '<svg xmlns="http://www.w3.org/2000/svg" width="1680" height="3201"><g fill="none" fill-rule="evenodd" stroke="#FFF" stroke-width="10"><path d="M0 208.5C194.248 202.46 335.683 6.759 610.963 47.567c290.349 43.042 365.34 290.823 612.349 280.16 247.008-10.662 53.574 264.864 243.908 277.013 70.211 4.481 144.74-12.405 213.28-36.24"/><path d="M0 45C22 25.5 128.5-6 209.5 146S461 454 767 454 1257.5 5 1536 5c57.856 0 107.23 6.535 144.5 20M0 2787c22-19.5 128.5-51 209.5 101S461 3196 767 3196s490.5-449 769-449c58.2 0 106.631 5.375 144 19m0-1591.5c-166.818 3.333-288.777 35.333-365.878 96-115.65 91-192.901 336-434.141 450.5s-449.953 5-634.723 167C122.078 1996 40.325 2104 0 2212"/><path d="M0 1562.5c166.768 4.667 288.691 37.333 365.769 98 115.616 91 192.844 336 434.012 450.5s449.82 5 634.535 167c123.143 108 205.038 219 245.684 333M0 593.5c171.912-26.71 536.153 100.784 666 206.825 150 122.5 96 260.5 15.5 260.5s-94-148.5-15.5-260.5 382.5-216.5 654 0c186.278 148.543 271.173 259.49 360 260.675"/></g></svg>'
      ),
    separator: rgba(0, 0, 0, 0.2),
    action-background: $__color-theme-light-foreground,
    action-foreground: #fff,
    form-accent: #317ada,
    // Lightest text color on white that meets WCAG AA accessibility requirement
    lightest-accessible: rgba(0, 0, 0, 0.58),
    disabled: #aaa,
    selection: #808080,
    primary-button-background: $__color_theme-light-brand,
    secondary-button-background: #fff,
    secondary-button-foreground: $__color-theme-light-brand,
    selection-background-color: $__color-theme-light-brand,
  ),
  dark: (
    type: 'dark',
    background: $__color-theme-dark-background,
    foreground: $__color-theme-dark-foreground,
    background-footer: $__color-theme-dark-secondary-background,
    foreground-footer: $__color-theme-dark-foreground,
    background-secondary: $__color-theme-dark-secondary-background,
    background-gradient:
      linear-gradient(
        to right,
        rgba(37, 37, 37, 0) 0%,
        rgba(37, 37, 37, 0.8) 50%,
        rgba(37, 37, 37, 0) 100%
      ),
    background-lines-news:
      svg-url(
        '<svg xmlns="http://www.w3.org/2000/svg" width="1680" height="3201"><g fill="none" fill-rule="evenodd" stroke="#343434" stroke-width="10"><path d="M0 208.5c194.2-6 335.7-201.7 611-161 290.3 43.1 365.3 290.9 612.3 280.2 247-10.6 53.6 264.9 244 277 70.1 4.5 144.7-12.4 213.2-36.2"/><path d="M0 45C22 25.5 128.5-6 209.5 146S461 454 767 454 1257.5 5 1536 5c57.9 0 107.2 6.5 144.5 20M0 2787c22-19.5 128.5-51 209.5 101S461 3196 767 3196s490.5-449 769-449c58.2 0 106.6 5.4 144 19m0-1591.5c-166.8 3.3-288.8 35.3-365.9 96-115.6 91-192.9 336-434.1 450.5-241.3 114.5-450 5-634.7 167C122 1996 40.3 2104 0 2212"/><path d="M0 1562.5c166.8 4.7 288.7 37.3 365.8 98 115.6 91 192.8 336 434 450.5 241.1 114.5 449.8 5 634.5 167 123.2 108 205 219 245.7 333M0 593.5c172-26.7 536.2 100.8 666 206.8 150 122.5 96 260.5 15.5 260.5s-94-148.5-15.5-260.5 382.5-216.5 654 0c186.3 148.6 271.2 259.5 360 260.7"/></g></svg>'
      ),
    separator: $__color-theme-dark-background,
    action-background: #fff,
    action-foreground: #333,
    form-accent: #317ada,
    // Lightest text color on white that meets WCAG AA accessibility requirement
    lightest-accessible: rgba(255, 255, 255, 0.42),
    disabled: #555,
    selection: #cecccc,
    primary-button-background: #fff,
    secondary-button-background: #333,
    secondary-button-foreground: #fff,
    selection-background-color: #fff,
  ),
);

/// Get a defined theme color.
///
/// @param {string} $theme - The theme name/ID.
/// @param {string} $color - The color name/ID.
/// @param {percentage} $tint-shade-amount [0%] - Amount of tint or shade to
///   apply to the color, depending on the theme.
/// @param {boolean} $tint-shade-reverse [false] - By default a tint/shade
///   value for light theme will darken the color. With $reverse true it will
///   be the opposite.
/// @return {color} The final color.
@function get-theme-color(
  $theme,
  $color,
  $tint-shade-amount: 0%,
  $tint-shade-reverse: false
) {
  $theme-data: map-get-strict($themes, $theme);
  $theme-color: map-get-strict($theme-data, $color);
  @if $tint-shade-amount != 0% {
    $theme-type: map-get-strict($theme-data, 'type');
    @if ($theme-type == 'light' and not $tint-shade-reverse) or
      ($theme-type == 'dark' and $tint-shade-reverse)
    {
      @return darken($theme-color, $tint-shade-amount);
    }
    @return lighten($theme-color, $tint-shade-amount);
  }
  @return $theme-color;
}

/// Get a plain light or dark color depending on the theme type.
///
/// @param {string} $theme - The theme name/ID.
/// @param {boolean} $reverse [false] - By default a light theme results in a
///   dark color. With $reverse true it will be the opposite.
/// @return {color} The resulting color.
@function get-theme-light-dark($theme, $reverse: false) {
  $theme-data: map-get-strict($themes, $theme);
  $theme-type: map-get-strict($theme-data, 'type');
  @if ($theme-type == 'light' and not $reverse) or
    ($theme-type == 'dark' and $reverse)
  {
    @return #222;
  }

  @return #fff;
}

/// Create a rule for every theme.
///
/// @param {string} $rule - The rule name (e.g. border-color).
/// @param {string} $color-name - Name/ID of the color to use for the rule.
/// @param {percentage} $tint-shade-amount [0%] - Amount of tint or shade to
///   apply to the color, depending on the theme.
/// @param {boolean} $tint-shade-reverse [false] - By default a tint/shade
///   value for light theme will darken the color. With $reverse true it will
///   be the opposite.
/// @param {string} $value-template ['{{color}}'] - Template for the rule value,
///   where {{color}} is replaced with the final color. Use for rules where the
///   the value contains more than just the color, e.g. '1px solid {{color}}'
///   for a border shorthand rule.
/// @param {boolean} $important [false] - Use !important.
@mixin theme-rule(
  $rule,
  $color-name,
  $tint-shade-amount: 0%,
  $tint-shade-reverse: false,
  $value-template: '{{color}}',
  $important: false
) {
  $val: unquote(
    str-replace(
      $value-template,
      '{{color}}',
      get-theme-color(
        $default-theme,
        $color-name,
        $tint-shade-amount,
        $tint-shade-reverse
      )
    )
  );
  @if $important {
    #{$rule}: $val !important;
  } @else {
    #{$rule}: $val;
  }

  @each $theme-name, $theme-data in $themes {
    @if $theme-name != $default-theme {
      $val: unquote(
        str-replace(
          $value-template,
          '{{color}}',
          get-theme-color(
            $theme-name,
            $color-name,
            $tint-shade-amount,
            $tint-shade-reverse
          )
        )
      );
      .theme-#{$theme-name} & {
        @if $important {
          #{$rule}: $val !important;
        } @else {
          #{$rule}: $val;
        }
      }
    }
  }
}

/// Create an rgba() rule for every theme.
///
/// @param {string} $rule - The rule name (e.g. border-color).
/// @param {number} $amount - Opacity between 0 and 1.
/// @param {string} $value-template ['{{color}}'] - Template for the rule value,
///   where {{color}} is replaced with the final color. Use for rules where the
///   the value contains more than just the color, e.g. '1px solid {{color}}'
///   for a border shorthand rule.
/// @param {boolean} $reverse [false] - By default a light theme results in a
///   black layer. With $reverse true it will be the opposite.
/// @param {boolean} $important [false] - Use !important.
@mixin theme-tint-shade-transparent(
  $rule,
  $amount,
  $value-template: '{{color}}',
  $reverse: false,
  $important: false
) {
  $light-rule: rgba(0, 0, 0, $amount);
  $dark-rule: rgba(255, 255, 255, $amount);
  @if $reverse {
    $light-rule: rgba(255, 255, 255, $amount);
    $dark-rule: rgba(0, 0, 0, $amount);
  }

  $val: unquote(str-replace($value-template, '{{color}}', $light-rule));
  @if $important {
    #{$rule}: $val !important;
  } @else {
    #{$rule}: $val;
  }

  @each $theme-name, $theme-data in $themes {
    @if $theme-name != $default-theme {
      $theme-type: map-get-strict($theme-data, 'type');
      .theme-#{$theme-name} & {
        @if $theme-type == 'light' {
          $val: unquote(str-replace($value-template, '{{color}}', $light-rule));
        } @else if $theme-type == 'dark' {
          $val: unquote(str-replace($value-template, '{{color}}', $dark-rule));
        }
        @if $important {
          #{$rule}: $val !important;
        } @else {
          #{$rule}: $val;
        }
      }
    }
  }
}

/// Create a plain light/dark color rule for every theme.
///
/// @param {string} $rule - The rule name (e.g. border-color).
/// @param {string} $value-template ['{{color}}'] - Template for the rule value,
///   where {{color}} is replaced with the final color. Use for rules where the
///   the value contains more than just the color, e.g. '1px solid {{color}}'
///   for a border shorthand rule.
/// @param {boolean} $reverse [false] - By default a light theme results in a
///   black layer. With $reverse true it will be the opposite.
/// @param {boolean} $important [false] - Use !important.
@mixin theme-light-dark(
  $rule,
  $value-template: '{{color}}',
  $reverse: false,
  $important: false
) {
  $val: unquote(
    str-replace(
      $value-template,
      '{{color}}',
      get-theme-light-dark($default-theme, $reverse)
    )
  );
  @if $important {
    #{$rule}: $val !important;
  } @else {
    #{$rule}: $val;
  }

  @each $theme-name, $theme-data in $themes {
    @if $theme-name != $default-theme {
      .theme-#{$theme-name} & {
        $val: unquote(
          str-replace(
            $value-template,
            '{{color}}',
            get-theme-light-dark($theme-name, $reverse)
          )
        );
        @if $important {
          #{$rule}: $val !important;
        } @else {
          #{$rule}: $val;
        }
      }
    }
  }
}

// Mixins for simple CSS rules
@mixin theme-foreground {
  @include theme-rule(color, 'foreground');
}
@mixin theme-background {
  @include theme-rule(background-color, 'background');
}
@mixin theme-background-secondary {
  @include theme-rule(background-color, 'background-secondary');
}
@mixin theme-action-foreground {
  @include theme-rule(color, 'action-foreground');
}
@mixin theme-action-background {
  @include theme-rule(background-color, 'action-background');
}
