/* -Icons
-----------------------------------------------------------------------------*/
/* AUTOGENERATED, DO NOT EDIT MANUALLY */

$icon-base-width: 16px;
$icon-base-height: 16px;
.icon {
  width: $icon-base-width;
  height: $icon-base-height;
  flex-grow: 0;
  flex-shrink: 0;
}

$icon-arrow-width: 22px;
$icon-arrow-height: 22px;
.icon--arrow {
  width: $icon-arrow-width;
  height: $icon-arrow-height;
}

$icon-chevron-width: 24px;
$icon-chevron-height: 24px;
.icon--chevron {
  width: $icon-chevron-width;
  height: $icon-chevron-height;
}