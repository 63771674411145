/* -Global bits and pieces
-----------------------------------------------------------------------------*/
html {
  scroll-behavior: smooth;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

body {
  position: relative;
}

// Push footer to bottom on short pages
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main {
  flex-grow: 1;
  outline: 0;
}

.page-content {
  // For absolutely positioned header on intro images
  position: relative;
  // For sliding small screen menu. Only set when needed since using position
  // sticky for the section nav doesn't work with any hidden overflow.
  @include media-max($breakpoint-menu-toggle) {
    //overflow-x: hidden;
    overflow-y: visible;
  }
}

// Class handled with JavaScript, which tries to take accessibility in mind.
// Sets hide-focus when a mouse is used and show focus when a keyboard is.
// Hide outlines for mouse users and make it more distinct for keyboard users.
a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
[tabindex] {
  .hide-focus & {
    outline: 0 !important;
  }
  .show-focus &:focus {
    @include theme-rule(
      $rule: outline,
      $color-name: 'foreground',
      $value-template: '3px dotted {{color}}'
    );
    outline-offset: 1px;
  }
  // Override outline colors when necessary
  .show-focus .site-header &:focus {
    outline-color: currentColor;
  }
}

.error-page-content {
  margin: 80px 20px 0;
  min-height: 50vh;

  @include media-max($breakpoint-grid-tight) {
    margin: 40px 0;
  }
}
