/* -General table styles
---------------------------------------------------------------------------- */
.table-wrap {
  overflow-x: auto;
  margin-bottom: $text-spacing;

  table {
    margin-bottom: 0;
  }
}

table {
  @include theme-rule(
    $rule: border-bottom,
    $color-name: 'separator',
    $value-template: '1px solid {{color}}'
  );
  @include theme-rule(
    $rule: border-top,
    $color-name: 'separator',
    $value-template: '1px solid {{color}}'
  );

  border-collapse: collapse;
}

th,
td {
  @include theme-rule(
    $rule: border-bottom,
    $color-name: 'separator',
    $value-template: '1px solid {{color}}'
  );
  @include theme-rule(
    $rule: border-top,
    $color-name: 'separator',
    $value-template: '1px solid {{color}}'
  );
  @include theme-rule(
    $rule: background-color,
    $color-name: 'background-secondary'
  );

  padding: 5px 10px;
  font-size: rem(14px);

  thead & {
    @include theme-rule($rule: background-color, $color-name: 'background');
  }
}

th {
  text-align: left;
}
