/* -Text variations
-----------------------------------------------------------------------------*/

@mixin large-content-text {
  @include media-min($breakpoint-type-small) {
    font-size: rem(18px);
  }
  @include media-min($breakpoint-type-medium) {
    font-size: rem(20px);
  }
}

// Any continuous text.
// Only targets direct children since some tags may be wrapped in a rich-text
// div which itself has last-child targets that don't should have their margins
// zeroed.
%running-text {
  > p,
  > ol,
  > ul {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.running-text {
  @extend %running-text;
}

// Tighter running text.
%tight-text {
  p,
  ol,
  ul {
    margin-bottom: 0;
    margin-top: 0.75em;

    &:first-child {
      margin-top: 0;
    }
  }
}
.tight-text {
  @extend %tight-text;
}

// Larger and smaller text.
%lead-text {
  margin-bottom: em($base-text-spacing, $lead-text-size);
  font-size: rem($lead-text-size);
  font-weight: $font-weight-light;

  @include media-min($breakpoint-type-small) {
    font-size: rem($lead-text-size + 6px);
  }

  @include media-min($breakpoint-type-medium) {
    font-size: rem($lead-text-size + 8px);
  }
}
@mixin lead-text-reset {
  margin-bottom: 0;
  font-size: 1em;
  font-weight: normal;
}

%sub-text {
  margin-bottom: em($base-text-spacing, $sub-text-size);
  font-size: rem($sub-text-size);
}

.lead-text {
  @extend %lead-text;
}
.sub-text {
  @extend %sub-text;
}

.lead-text,
.sub-text {
  p,
  ol,
  ul {
    margin-bottom: inherit;
  }
}

// Inline text alongside things like icons.
.text {
  vertical-align: middle;

  // Restore default alignment if it appears on its own.
  &:only-child {
    vertical-align: baseline;
  }
}
