/* --------------- List of news cards --------------- */

.news-list {
  @include section-padding;
  @include section-pull-top;
}

.news-cards-wrap {
  @include wrap-custom(rem(400px));
}

.news-cards {
  @include grid-container;
  @include grid-pull-bottom;
}
.news-card {
  @include grid-item(1);
  @include grid-margin-bottom;
}
.news-card .overlay-link {
  @include grid-gutter-rule(left);
}

@include media-min(rem(650px)) {
  .news-cards {
    display: flex;
    flex-wrap: wrap;
  }
  .news-card {
    display: flex;
    flex-direction: column;
  }
  .news-card-inner {
    flex-grow: 1;
    min-height: 200px;
  }

  .news-cards-wrap--cols-1 {
    @include wrap-width-custom(rem(450px));
  }
  .news-cards-wrap--cols-2,
  .news-cards-wrap--cols-3,
  .news-cards-wrap--cols-4 {
    @include wrap-width-thin;

    .news-card {
      @include grid-item-size(1 / 2);
    }
  }
}

@include media-min(rem(1100px)) {
  .news-cards-wrap--cols-3 {
    @include wrap-width-wide;

    .news-card {
      @include grid-item-size(1 / 3);
    }
  }
}
