/* -Contact form block
-----------------------------------------------------------------------------*/
.contact-form-block {
  align-items: center;

  .form-footer {
    button {
      width: 100%;
    }
  }
}
.contact-form-block-content {
  padding-bottom: 1rem;
}
.contact-form__image {
  max-width: 150px;
}

@include media-min(rem(700px)) {
  .contact-form-block {
    @include grid-container;
  }
  .contact-form-block-content {
    @include grid-item(3/5);
  }
  .contact-form-block-form {
    @include grid-item(2/5);
  }
  .contact-form-block-content {
    padding-bottom: 0;
  }
  .contact-form-block-content,
  .contact-form-block label {
    font-size: rem(20px);
  }
}
