/* -Into image with content
---------------------------------------------------------------------------- */

// Keep duration in sync with Intro.js
$intro-animation-duration: 4000ms;
$intro-animation-pan: 2%;
$intro-animation-scale: 1.1;

$initial-intro-transform: translate(0, 0) scale(1);
@keyframes intro-top-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-top-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}

// Must have a wrapping column flex container for IE11
// https://github.com/philipwalton/flexbugs#flexbug-3
.intro-container {
  display: flex;
  flex-direction: column;
}

.intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;
  max-height: 800px;

  @media screen and (orientation: landscape) {
    min-height: 50vh;
  }
  @media screen and (orientation: portrait) {
    min-height: 40vh;
  }
}

.intro-bg {
  // Ensure text is readable if image or video fails to load
  background-color: #808080;

  &,
  picture {
    // Need to override flickity base styles.
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // Ensure the first element is on top to prevent flashing when Flickity is
  // initiated, since it sets the first slide as selected. This will only
  // target non-Flickity picture since wrapping divs are added for the slider.
  > picture:first-child {
    z-index: 2;
  }
}
.intro-image-active {
  // Add buffer to keep the animation going as the active slide changes
  animation-duration: $intro-animation-duration + 2000ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  &[data-direction='top-left'] {
    animation-name: intro-top-left;
  }
  &[data-direction='top-right'] {
    animation-name: intro-top-right;
  }
  &[data-direction='bottom-right'] {
    animation-name: intro-bottom-right;
  }
  &[data-direction='bottom-left'] {
    animation-name: intro-bottom-left;
  }
}

.intro-content {
  position: relative;
  width: 100%;
  padding-top: rem(160px);
  padding-bottom: rem(140px);
  text-align: center;
  color: #fff;
  z-index: 10;

  h1,
  h2,
  h3,
  h4,
  h5 p {
    margin-bottom: rem(10px);
    text-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  }

  @include media-max($breakpoint-menu-toggle) {
    padding-top: rem(140px);
    padding-bottom: rem(120px);
  }
}
