$wrap-width-extra-thin: rem(620px);
$wrap-width-thin: rem(760px);
$wrap-width: rem(1000px);
$wrap-width-wide: rem(1240px);

$page-margin: rem(60px);
$page-margin-small: rem(25px);

$section-spacing: 100px;
$section-spacing-small: $section-spacing / 2;

$header-padding: 20px;

$arrow-link-padding: 5px;

// Form elements
// Using em instead of rem so that dimensions adapt to local font size changes
$form-control-base-height: 40px;
$form-control-font-size: 15px;
$form-control-radius: 5px;
$form-field-padding: em(10px, $form-control-font-size);
$form-control-height: em($form-control-base-height, $form-control-font-size);
$button-font-size-large: 16px;
$button-font-size-small: 14px;
$button-height: em($form-control-base-height, $button-font-size-large);

/*-------------------- Common breakpoints --------------------*/
// Most breakpoints should be isolated to what they affect and not be some
// arbitrary values, but global changes like text size may require adjustment
// to details in some instances and should therefore be a defined variable.

// Reduce text sizes
$breakpoint-type-medium: rem(1200px);
$breakpoint-type-small: rem(550px);

// Tighten layout
$breakpoint-grid-tight: rem(700px);
$breakpoint-page-margin-small: rem(700px);

$breakpoint-menu-toggle: rem(900px);
