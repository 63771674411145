/* -Lazy loaded images
---------------------------------------------------------------------------- */
// The lazy-image container has padding bottom set for the aspect ratio trick.

.lazy-image {
  position: relative;
  // Ensure the container doesn't collapse to zero size since screen readers
  // may then possibly ignore it.
  height: 1px !important;
  min-width: 1px;

  img {
    position: absolute;
    top: 0;
    left: 0;

    // No background for potentially transparent images like PNG.
    &[data-src$='jpg'],
    &[src$='jpg'] {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
