/* -Aspect ratio containers
-----------------------------------------------------------------------------*/
@mixin aspect-ratio($ratio) {
  @if type-of($ratio) != string {
    @error '[aspect-ratio mixin] The aspect ratio must be a string formatted as `16:9`, got a #{type-of($ratio)}';
  }

  $parts: str-split($ratio, ':');
  @if length($parts) != 2 {
    @error '[aspect-ratio mixin] Expected two aspect ratio parts, got #{length($parts)}';
  }

  padding-bottom: percentage(
    (to-number(nth($parts, 2)) + 0) / (to-number(nth($parts, 1)) + 0)
  );
}

// `responsive-object` is Wagtail's wrapper for embeded videos
.responsive-object,
.aspect-ratio {
  // Default to 16:9
  @include aspect-ratio('16:9');

  position: relative;
  box-sizing: content-box;
  // Screen readers may ignore content with zero height
  height: 1px;
  margin-bottom: -1px;

  > iframe,
  > div {
    position: absolute;
    top: 0;
    left: 0;
    // Some services, like Issuu, has inline dimensions
    width: 100% !important;
    height: 100% !important;
  }
}

.responsive-object {
  margin-bottom: $text-spacing;
}
