/* AUTOGENERATED, DO NOT EDIT MANUALLY */

/* heebo-latin-300-normal*/
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('../fonts/heebo-latin-300-normal.woff2') format('woff2'), url('../fonts/heebo-latin-300-normal.woff') format('woff');
  
}

/* heebo-latin-400-normal*/
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/heebo-latin-400-normal.woff2') format('woff2'), url('../fonts/heebo-latin-400-normal.woff') format('woff');
  
}

/* heebo-latin-500-normal*/
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../fonts/heebo-latin-500-normal.woff2') format('woff2'), url('../fonts/heebo-latin-500-normal.woff') format('woff');
  
}

/* heebo-latin-700-normal*/
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/heebo-latin-700-normal.woff2') format('woff2'), url('../fonts/heebo-latin-700-normal.woff') format('woff');
  
}
