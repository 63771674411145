/* -Staff block
-----------------------------------------------------------------------------*/
/*--------------- Staff item ---------------*/
.staff-item {
  max-width: 385px;
  text-align: left;

  @include media-min($breakpoint-grid-tight) {
    max-width: 420px;
  }
}

.staff-item-image {
  @include theme-rule(
    $rule: border,
    $color-name: 'separator',
    $value-template: '1px solid {{color}}'
  );
}
.staff-item-content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow-wrap: break-word;

  @include media-min($breakpoint-type-small) {
    font-size: rem(18px);
  }
}

.staff-item-name {
  margin-bottom: 0;
  font-weight: $font-weight-bold;
}
.staff-item-phone {
  color: inherit;
  text-decoration: none;
}

/*--------------- List of staff ---------------*/

.staff-block-wrapper {
  @include section-padding;

  text-align: center;

  > *:first-child {
    margin-top: 0 !important;
  }
}
.staff-block {
  display: flex;
  justify-content: center;
}
.staff-items {
  @include grid-container;
  @include grid-pull-bottom;

  text-align: center;
}
.staff-item {
  @include grid-item(1);
  @include grid-margin-bottom;
}

@include media-min(rem(700px)) {
  .staff-block--cols-2,
  .staff-block--cols-3 {
    @include wrap-custom(rem(770px));

    .staff-items {
      text-align: left;
    }

    .staff-item {
      @include grid-item(1 / 2);
    }
  }
}

@include media-min(rem(1000px)) {
  .staff-block--cols-3 {
    @include wrap-custom(rem(1200px));

    .staff-item {
      @include grid-item(1 / 3);
    }
  }
}
