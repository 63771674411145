/* -'Case' block with image and content with specific section styling
-----------------------------------------------------------------------------*/
$image-content-grid-gutter: 80px;

$case-columns-breakpoint: 1100px;

.case-block {
  @include section-padding;
  @include wrap-wide;
  @include large-content-text;

  .link-block {
    a {
      color: inherit;
    }
  }
}

.case-block-image {
  margin-bottom: 3em;
  display: flex;
  justify-content: center;
}

.case-block-content {
  max-width: 620px;
}

// Go to two columns
@include media-min(rem($case-columns-breakpoint)) {
  .case-block-inner {
    display: flex;
  }
  .case-block-content {
    justify-content: center;
  }
  .case-block-image {
    margin-bottom: 0;
  }
}

// Sections with image to right
.section-block--white-image-to-right,
.section-block--light-grey,
.section-block--sajkla {
  .case-block-inner {
    flex-direction: row-reverse;
  }
}

/* -'Green and white' section
-----------------------------------------------------------------------------*/
.section-block--green-and-white {
  .case-block-image {
    display: flex;
    justify-content: center;

    img {
      max-height: 250px;
      max-width: 300px;

      @include media-min(rem(500px)) {
        max-height: 300px;
        max-width: 400px;
      }
    }
  }

  // Medium screens
  @include media-min(rem(650px)) {
    .case-block {
      padding-top: $section-spacing;
      padding-bottom: $section-spacing;
    }
    .case-block-inner {
      display: flex;
      justify-content: flex-end;
    }
    .case-block-content {
      display: flex;
      flex-direction: column;
      width: 67%;
    }
    .case-block-image {
      position: absolute;
      left: -10%;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      margin-right: 5%;
      width: 38%;

      img {
        margin-bottom: 0;
        height: auto;
        max-height: 550px;
        max-width: 100%;
      }
    }
  }

  // Large screens
  @include media-min(rem($case-columns-breakpoint)) {
    .case-block-image {
      position: static;
      transform: none;
      margin-right: 15% !important;
      width: 40%;
      img {
        margin-bottom: 0;
        max-height: 720px;
        width: auto;
      }
    }
  }
}

/* -'White background and image to right' section
-----------------------------------------------------------------------------*/
.section-block--white-image-to-right {
  .case-block {
    @include wrap-extra-thin;
  }
  .case-block-inner {
    flex-direction: column;
  }

  @include media-min(rem($case-columns-breakpoint)) {
    .case-block {
      @include wrap-wide;
    }
    .case-block-content {
      width: 50%;
    }
    .case-block-image {
      margin-right: 0 !important;

      img {
        max-height: 540px;
        max-width: 55%;
        position: absolute;
        right: -10%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  @include media-min(rem(1300px)) {
    .case-block-image {
      img {
        max-width: 55%;
        left: 55%;
        right: auto;
      }
    }
  }
}

/* -'Dark grey' section
-----------------------------------------------------------------------------*/
.section-block--dark-grey {
  padding-bottom: 0 !important;

  .case-block {
    @include wrap-extra-thin;
    @include section-padding-top(2);
    padding-bottom: 0;
  }
  .case-block-inner {
    display: flex;
    flex-direction: column-reverse;
  }
  .case-block-image {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    img {
      max-height: 300px;

      @include media-min(rem(600px)) {
        max-height: 400px;
      }
    }
  }

  @include media-min(rem(800px)) {
    padding-bottom: $section-spacing !important;

    .case-block {
      @include wrap-wide;
    }
    .case-block-inner {
      width: 100%;
      justify-content: flex-start;
    }
    .case-block-content {
      width: 65%;
    }

    .case-block-image {
      justify-content: flex-start;
      position: absolute;
      right: -25%;
      bottom: 0;
      margin-top: 0;
      width: 60%;
      img {
        max-height: 445px;
      }
    }
  }

  @include media-min(rem(1300px)) {
    .case-block-inner {
      justify-content: flex-end;
      flex-direction: row;
    }
    .case-block-content {
      width: 50%;
    }
    .case-block-image {
      position: absolute;
      bottom: 0;
      right: 55%;
      max-width: 40%;
      width: auto;
      margin-right: 0 !important;
      display: block;
      img {
        max-height: 615px;
      }

      @include media-min(rem(1300px)) {
        right: 55%;
      }
    }
  }
}

/* -'Light grey' section
-----------------------------------------------------------------------------*/
.section-block--light-grey {
  .case-block {
    @include wrap-extra-thin;

    padding-bottom: 0 !important;
  }
  .case-block-inner {
    display: flex;
    flex-direction: column-reverse;
  }
  .case-block-content {
    width: 100%;
  }
  .case-block-image {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    position: relative;
    height: 300px;

    @include media-min(rem(500px)) {
      height: 400px;
    }

    img {
      position: absolute;
      max-height: 100%;
      top: 12%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include media-min(rem(850px)) {
    padding-bottom: $section-spacing !important;
    .case-block {
      @include wrap-wide;
      @include section-padding(0);
    }
    .case-block-inner {
      flex-direction: row;
      justify-content: flex-start;
    }
    .case-block-content {
      width: 60%;
    }

    .case-block-image {
      position: absolute;
      top: -10%;
      width: 70%;
      right: -30%;
      height: auto;
      img {
        max-height: 600px;
        position: static;
        transform: none;
      }
      @include media-min(rem(1000px)) {
        width: 60%;
        right: -20%;
      }
    }
  }
  @include media-min(rem(1200px)) {
    .case-block {
      @include section-padding;
    }

    .case-block-content {
      width: 50%;
      max-width: 650px;
      padding-right: 30px;
    }
    .case-block-image {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 50%;
      right: auto;
      width: auto;
      img {
        max-width: 150%;
        max-height: 800px;
      }
    }
  }
}

/* -'White with image to left' section
-----------------------------------------------------------------------------*/
.section-block--white-image-to-left {
  .case-block {
    @include wrap-extra-thin;
  }

  @include media-min(rem($case-columns-breakpoint)) {
    .case-block {
      @include wrap-wide;
    }
    .case-block-inner {
      flex-direction: row;
    }
    .case-block-image {
      margin-right: $image-content-grid-gutter;
      width: 50%;
      img {
        width: 100%;
      }
    }
    .case-block-content {
      width: 50%;
    }
  }
}

/* -'Red' section
-----------------------------------------------------------------------------*/
.section-block--red {
  padding-top: 0 !important;
  .case-block {
    @include wrap-extra-thin;

    padding-top: 0;
  }

  @include media-min(rem($case-columns-breakpoint)) {
    padding-top: $section-spacing !important;
    .case-block {
      @include wrap-wide;
      @include section-padding(2);
    }

    .case-block-content {
      width: 50%;
    }
    .case-block-image {
      display: block;
      position: absolute;
      left: 51%;
      top: 50%;
      width: 50%;
      transform: translateY(-50%);
      img {
        max-height: 600px;
        width: auto;
        max-width: 120%;
      }
    }
  }
}

/** Section block theme Sajkla */
.section-block--sajkla {
  .case-block {
    @include wrap-extra-thin;
  }
  .case-block-image {
    img {
      max-height: 350px;
    }
  }

  @include media-min(rem(700px)) {
    .case-block {
      @include wrap-wide;
    }
    .case-block-content {
      width: 50%;
    }
    .case-block-inner {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    .case-block-image {
      display: block;
      position: absolute;
      left: 55%;
      top: 50%;
      transform: translateY(-50%) rotate(-5deg);
      width: 50%;
      img {
        max-height: 600px;
      }
    }
  }

  @include media-min(rem($case-columns-breakpoint)) {
    .case-block-content {
      // Set min-height to avoid absolute positioned image to go outside parent
      //container
      min-height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
