/* -Cards block
---------------------------------------------------------------------------- */
$card-columns-gutter: 30px;
$breakpoint-card-cols-4: 1150px;

.card-list-wrap {
  @include section-margin;

  @include media-min(rem($breakpoint-card-cols-4)) {
    &.card-list-wrap--cols-4 {
      @include wrap-wide;
    }
  }
}
.card-list {
  @include grid-container($card-columns-gutter, $card-columns-gutter);
  @include grid-pull-bottom($card-columns-gutter, $card-columns-gutter);

  justify-content: center;
}

.card {
  @include grid-item(1, $card-columns-gutter, $card-columns-gutter);
  @include grid-margin-bottom($card-columns-gutter, $card-columns-gutter);

  max-width: 360px !important;
  padding-top: 28px;

  &.card--is-popular {
    .card-inner {
      position: relative;

      .card-is-popular-box {
        position: absolute;
        top: -28px;
        left: 20px;
        right: 20px;
        padding: 5px 0;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        font-size: rem(12px);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        background-color: $color-blue;
        color: #fff;
      }
    }
  }

  .card-list--cols-1 & {
    @include grid-item-size(1);
  }

  @include media-min(rem(600px)) {
    .card-list--cols-2 &,
    .card-list--cols-3 &,
    .card-list--cols-4 & {
      @include grid-item-size(1/2);
    }
  }
  @include media-min(rem(950px)) {
    .card-list--cols-3 & {
      @include grid-item-size(1/3);
    }
  }

  @include media-min(rem($breakpoint-card-cols-4)) {
    .card-list--cols-4 & {
      @include grid-item-size(1/4);
    }
  }
}
.card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 5px 10px rgba(#000, 0.28);
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
}

.card-top {
  min-height: 8.5em;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card-image {
  flex-shrink: 2;
  margin-bottom: 10px;
  img {
    max-height: 100px;
  }
}
.card-image-caption {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  font-size: rem(14px);
  color: $__color-theme-light-brand;
  max-width: 120px;
}

.card-price {
  min-height: 6em;
}
.card-title {
  margin-bottom: 0;
  line-height: 1;
  font-size: rem(28px);
}
.card-title-desc {
  font-weight: $font-weight-light;
}
.card-content {
  padding: 40px 25px;
  position: relative;
  flex-grow: 1;
  background-color: rgba($color-blue, 0.05);
  font-size: 14px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    background-color: #fff;
    width: 100%;
    height: 10px;
    // 100%(width), 50%(center), 100%(height)
    clip-path: polygon(100% 0, 0 0, 50% 100%);
  }
}
.card-text {
  text-align: left;

  ul {
    margin-left: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 1.5em;
      margin-bottom: 0.5em;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0.25em;
        left: 0;
        width: 1em;
        height: 1em;
        background-size: 1em;
        background-image: svg-url(
          '<svg width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="#1A4DCD"/><path fill="#FFF" d="M16 5.8a1.5 1.5 0 112 2.4c-1.3 1-2.6 2.9-4 5.6l-.5.8-.2.4-.4.8a74 74 0 00-1 2.3c-.5 1-2 1.2-2.6.2A20.4 20.4 0 005 13.7a1.5 1.5 0 111.8-2.4l3.1 3 .2.3v-.2c2-4 3.8-6.8 5.6-8.4l.2-.2z"/></svg>'
        );
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
