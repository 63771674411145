a:link,
a:visited {
  color: inherit;
}
a:hover,
a:focus {
  text-decoration: none;
}

%overlay-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  text-indent: -999px;
  font-size: 12px;
  z-index: $z-overlay-link;

  /* A 10x10 transparent PNG; a fix for the link not being clickable in IE */
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABdJREFUeNpi/P//PwMxgHFUIX0VAgQYACK+He3OQHX/AAAAAElFTkSuQmCC') !important;
}
.overlay-link {
  @extend %overlay-link;
}

.skiplink {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: $z-skiplink;

  &:focus {
    @include theme-action-background;
    @include theme-action-foreground;

    display: inline-block;
    padding: 5px 10px !important;
  }
}
