/* -Contact person block with image and content
-----------------------------------------------------------------------------*/
$contact-person-columns-breakpoint: 650px;

.contact-person-block {
  @include section-margin-top(2.5);

  &:not(:last-child) {
    @include section-margin-bottom;
  }
}
.contact-person-block--background {
  position: relative;

  &::before {
    @include theme-rule(
      $rule: background-color,
      $color-name: 'background-secondary'
    );

    position: absolute;
    content: '';
    top: -10%;
    left: 0;
    bottom: 0;
    width: 100%;
    clip-path: polygon(0 $section-spacing/2, 100% 0, 100% 100%, 0% 100%);
  }

  @include media-min(rem($contact-person-columns-breakpoint)) {
    &::before {
      top: -$section-spacing;
      clip-path: polygon(0 $section-spacing, 100% 0, 100% 100%, 0% 100%);
    }
  }
}

.contact-person-block-inner {
  display: flex;
  flex-direction: column-reverse;
}

.contact-person-block-image {
  margin-top: 1em;
  position: relative;
  img {
    max-height: 250px;
  }
}

.contact-person-block-content {
  padding-top: $section-spacing / 2;
  position: relative;

  @include media-min(rem(800px)) {
    font-size: rem(18px);
  }
  @include media-min(rem(1000px)) {
    font-size: rem(20px);
  }
}
@include media-min(rem(500px)) {
  .contact-person-block-image {
    img {
      max-height: 350px;
    }
  }
}

@include media-min(rem($contact-person-columns-breakpoint)) {
  .contact-person-block-inner {
    flex-direction: row;
  }
  .contact-person-block-image {
    position: relative;
    align-self: flex-end;
    margin-right: 40px;
    margin-top: 0;
    flex-grow: 1;

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      max-height: none;
      z-index: 11;
    }
  }
  .contact-person-block-content {
    padding-top: $section-spacing/2;
    padding-bottom: $section-spacing;
    max-width: 620px;
    width: 52%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    > div {
      position: relative;
    }
  }
}

@include media-min(rem(800px)) {
  .contact-person-block-content {
    width: 62%;
  }
}
