/* -Pagination links
---------------------------------------------------------------------------- */
.pagination {
  @include section-margin-top;

  display: flex;
  align-items: center;

  a {
    color: inherit;
  }
}

.pagination-info {
  margin: 0 auto 0 0;
}

.pagination-prev ~ .pagination-next {
  margin-left: 30px;
}

// Pagination news detail
.news-detail-pagination {
  margin-top: 0;
  @include section-margin-bottom;

  .pagination-next {
    margin-left: auto;
  }

  .pagination-prev {
    margin-right: auto;
  }
}
