/* -Main logo
---------------------------------------------------------------------------- */
.logo {
  display: inline-block;
  max-width: 150px;

  @include media-min(rem(370px)) {
    max-width: 200px;
  }

  @include media-min(rem(900px)) {
    max-width: 270px;
  }
}
