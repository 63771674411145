/* -News article detail
---------------------------------------------------------------------------- */
.news-article {
  @include section-margin(0.5);
  @include section-padding(1.5);
  @include large-content-text;

  @include theme-rule(
    $rule: background-color,
    $color-name: 'background-secondary'
  );

  position: relative;
  clip-path: polygon(0 0, 100% 40px, 100% calc(100% - 40px), 0 100%);

  @include media-min($breakpoint-page-margin-small) {
    clip-path: polygon(0 0, 100% 60px, 100% calc(100% - 60px), 0 100%);
  }

  .publish-date {
    font-weight: $font-weight-light;
    @include when-page-margin-large {
      font-size: 24px;
    }
  }

  hr {
    @include theme-rule($rule: color, $color-name: 'separator');
  }
}
.news-article-title {
  margin-bottom: 20px;
}
.news-article-image {
  margin-top: 20px;
  margin-bottom: 40px;
}
.news-article-content {
  padding-bottom: 20px;
}

// Tags
.news-detail-tags {
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.news-detail-tag {
  margin-right: 15px;

  @include media-min(rem(500px)) {
    margin-right: 30px;
  }
}
