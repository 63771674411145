/* -FAQ -block
---------------------------------------------------------------------------- */
.no-js {
  .faq-block__answer {
    display: block !important;
  }
}
.faq-block {
  @include section-margin;
}
.faq-block__content {
  @include section-margin(0.5);
  padding: 0;
}
.faq-block__item {
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

  &:last-child {
    margin-bottom: 0;
  }
}
.faq-block__question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 15px;

  .icon {
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
    transition: transform 0.2s ease;
    border-radius: 50%;
    background-color: $color-blue;
    color: #fff;

    .faq-block__item--open & {
      transform: rotate(270deg);
      transition: none;
    }
  }
}
.faq-block__question-text {
  margin-bottom: 0;
}
.faq-block__toggle {
  flex-shrink: 0;
  height: 50px;
  width: 50px;

  &:hover,
  &:focus {
    .icon {
      background-color: darken($color-blue, 10%);
    }
  }
}
.faq-block__answer {
  display: none;
  padding: 0 15px 10px 15px;

  .faq-block__item--open & {
    display: block;
  }
}
@include media-min(rem(600px)) {
  .faq-block__toggle {
    display: none;
  }
  .faq-block__question {
    padding: 25px 25px 0 25px;
  }
  .faq-block__answer {
    display: block;
    padding: 20px 25px;
  }
}
