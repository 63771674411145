/* -Blog like list of pages
---------------------------------------------------------------------------- */
.page-list {
  @include section-margin;
}

.page-list-item {
  @include section-margin-bottom;

  overflow: hidden;

  .publish-date {
    margin-bottom: 10px;
    font-size: rem(13px);
  }
}

.page-list-item-image {
  float: left;
  max-width: 250px;
  margin-right: 20px;
}

.page-list-item-content {
  overflow: hidden;
}
